/* eslint-disable prettier/prettier */
export const formatReviews = ({ title, image, reviewsData }) => {
  const sku = reviewsData?.sku
  const reviewsItems =
    reviewsData?.sortedReviews || reviewsData?.reviewsItems || (Array.isArray(reviewsData) ? reviewsData : null)
  const averageRating = reviewsData?.averageRating
  const ratingBreakdown = reviewsData?.ratingBreakdown
  const ratingsCount = reviewsData?.ratings
  const reviewsCount = reviewsData?.reviews || reviewsItems?.length || 0
  const productReviewsItems = reviewsData?.productReviews || []
  const relatedReviewsItems = reviewsData?.relatedReviews || []
  const reviewsSource = reviewsData?.productReviews ? 'product' : 'store'

  return {
    sku,
    title,
    image,
    ratingBreakdown,
    ratingsCount,
    reviewsCount,
    averageRating,
    reviewsItems,
    productReviewsItems,
    relatedReviewsItems,
    reviewsSource,
  }
}

export const formatReviewsItems = review => {
  const reviewerDisplayName =
    review?.reviewerDisplayName || (review?.user ? `${review?.user?.firstName} ${review?.user?.lastName}` : '')
  const starRating = review?.starRating || review?.rating || 0
  const updateTime = review?.updateTime || review?.reviewedDate
  const purchaseDate = review?.purchaseDate
  const title = review?.title
  const productTitle = review?.product?.title
  const productUrl = review?.product?.url
  const comment = review?.comment || review?.text
  const isVerifiedUser = !!review?.user

  return {
    reviewerDisplayName,
    starRating,
    updateTime,
    title,
    productTitle,
    productUrl,
    comment,
    purchaseDate,
    isVerifiedUser,
  }
}

export const getRatingsProperties = (starNumber, reviewsList, ratingsQty, defaultRating, decimal = 0.0) => {
  const ratingsStars =
    defaultRating?.[starNumber] || reviewsList?.filter(review => +review?.starRating === +starNumber)?.length
  const ratingPercentage = (ratingsStars * 100) / ratingsQty + decimal
  const ratingsStarsPercentage = Math.trunc(ratingPercentage)
  const truncatedDecimal = ratingPercentage % 1
  const reviewsStars = reviewsList?.filter(review => +(review?.starRating || review?.rating) === starNumber)
  return [ratingsStars, ratingsStarsPercentage, reviewsStars, truncatedDecimal]
}
